.outerContainer {
  display: flex;
  justify-content: center; /* Horizontally center the container */
  align-items: center; /* Vertically center the container */
  background-color: #fff;
}

.innerContainer {
  width: 100%;
  max-width: 400px;
  text-align: center;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Added box-shadow */
  background-color: #fff;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.italicText {
  font-style: italic;
}

.pickerContainer,
.submitButton {
  display: block;
  width: 100%;
  padding: 12px;
  margin-top: 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submitButton:hover {
  background-color: #0056b3;
}

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalScrollView {
  width: 80%;
  max-width: 400px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  overflow-y: auto;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modalItem {
  padding: 15px;
  border-bottom: 1px solid #ccc;
  cursor: pointer;
}

.modalItem:last-child {
  border-bottom: none;
}

.modalItem:hover {
  background-color: #f0f0f0; /* Change background color on hover */
}

.modalItem button {
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 10px;
  text-align: left;
  font-size: 16px;
}

.modalItem button:hover {
  background-color: #f0f0f0; /* Change background color on hover */
}
